import React, { createContext, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';

import { ContextParams, Props } from './types';
import { ChatForm } from 'store/messages/omniChannel/types';

const initialValuesInfo: ChatForm['form'] = {
  message: '',
  file: null,
};

export const ChatContext = createContext<Props>({
  form: { values: initialValuesInfo },
} as Props);

const ChatProvider: React.FC<ContextParams> = ({ children }) => {
  const { t } = useTranslation('messages');

  const tBase = useCallback(
    (key: string, params?: Record<string, string | number>) =>
      t(`omni_channel.chat.${key}`, params),
    [t]
  );

  const [currentStep, setCurrentStep] = useState(1);

  const onSubmit = (values: ChatForm['form']) => {};

  const form = useFormik({
    initialValues: initialValuesInfo,
    onSubmit: onSubmit,
    validateOnChange: true,
    isInitialValid: false,
  });

  return (
    <ChatContext.Provider
      value={{
        form,
        currentStep,
        setCurrentStep,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export default ChatProvider;
